import { Link, NavLink } from "react-router-dom";
import { TfiClose, TfiMenu } from "react-icons/tfi";
import React, { useEffect, useState } from "react";
import logo from "../../assets/icons/Lavisha_Black.svg";
import logoWhite from "../../assets/icons/Lavisha_White.svg";
import Modal from "../../components/modal";
import ModalContent from "../../components/modalcontent";
const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  useEffect(() => {
    const listenToScroll = () => {
      if (window.scrollY > 80) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <>
      <header className={"style-header " + (scrolled ? "affix" : "")}>
        <div className="header-bg">
          <div className="header-group">
            <div className="logo-nav">
              <NavLink to="/">
                <img src={logo}></img>
              </NavLink>
            </div>

            <div className="nav-menu ">
              <ul className={click ? "nav-options active" : "nav-options"}>
                <div className="nav-mobile-title">
                  <Link to="/">
                    <img src={logoWhite} className="mobile-logo"></img>
                  </Link>

                  <TfiClose size={24} onClick={closeMobileMenu} />
                </div>

                <div className="nav-options-group section-detail  ">
                  <li className="option">
                    <NavLink to="/about-us">About Us</NavLink>
                  </li>
                  <li
                    className="dropdown-web option"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <>
                      Our Brands
                      {isDropdownVisible && (
                        <div className="dropdown-menu">
                          <ul className="brands-group">
                            <li className="brands-item">
                              <NavLink to="http://lbb.lavishamsia.com/">
                                LBB
                              </NavLink>
                            </li>

                            <li className="brands-item">
                              <NavLink to="http://lemiele.lavishamsia.com/">
                                Le Miele
                              </NavLink>
                            </li>
                            <li className="brands-item">
                              <NavLink to="http://larc.lavishamsia.com/">
                                L’arc
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                    </>
                  </li>

                  <li className="dropdown-mobile option">
                    <>
                      Our Brands
                      <div className="dropdown-menu">
                        <ul className="brands-group">
                          <li className="brands-item">
                            <NavLink to="http://lbb.lavishamsia.com/">
                              LBB
                            </NavLink>
                          </li>

                          <li className="brands-item">
                            <NavLink to="http://lemiele.lavishamsia.com/">
                              Le Miele
                            </NavLink>
                          </li>
                          <li className="brands-item">
                            <NavLink to="http://larc.lavishamsia.com/">
                              L’arc
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </>
                  </li>
                  <li className="option">
                    <NavLink to="/lavisha-way">Lavisha Way</NavLink>
                  </li>
                  <li className="option">
                    <NavLink to="ba-academy">BA Academy</NavLink>
                  </li>
                </div>
              </ul>
              <ul className="contact-web">
                <li className="option">
                  <button
                    onClick={() => setModalOpen(true)}
                    className="nav-primary"
                  >
                    Contact Us
                  </button>
                </li>
              </ul>
            </div>

            <div className="mobile-menu">
              <div onClick={closeMobileMenu} className="contact-mobile">
                <button
                  onClick={() => setModalOpen(true)}
                  className="nav-primary"
                >
                  Contact Us
                </button>
              </div>

              <div className="menu-toggle" onClick={handleClick}>
                {click ? <TfiClose size={24} /> : <TfiMenu size={24} />}
              </div>
            </div>
          </div>
        </div>
      </header>
      {isModalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <ModalContent></ModalContent>
        </Modal>
      )}
    </>
  );
};

export default Header;
