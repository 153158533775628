import ba_sterwina from "../../assets/images/ba_academy/sterwina.jpg";
import ba_farah from "../../assets/images/ba_academy/farahgoh.jpg";
import ba_selene from "../../assets/images/ba_academy/selenethien.jpg";
import ba_zizi from "../../assets/images/ba_academy/zizihau.jpg";
import ba_sharonchiew from "../../assets/images/ba_academy/sharonchiew.jpg";
import ba_jannethchong from "../../assets/images/ba_academy/jannethchong.jpg";

const baNews = [
  {
    id: 4,
    date: "",
    src: ba_jannethchong,
    type: "Janneth Chong",
    detail:
      "The support system and working environment at Lavisha Malaysia have contributed to my personal growth, increasing my confidence and helping me define my career vision.",
  },
  {
    id: 5,
    date: "",
    src: ba_selene,
    type: "Selene Thien",
    detail:
      "I see future and personal growth in Lavisha, as it aligns with my passion for holistic wellness and provides a platform to innovate and make a meaningful impact in the beauty and wellness industry.",
  },
  {
    id: 6,
    date: "",
    src: ba_sharonchiew,
    type: "Sharon Chiew",
    detail:
      "Lavisha Malaysia provides a solid foundation for my career growth. The company is well-established and committed to fostering my growth and success.",
  },
  {
    id: 1,
    date: "",
    src: ba_sterwina,
    type: "Sterwina",
    detail:
      "I'm loving LBB Cell Beauty! Their skincare products truly deliver results, from luxurious moisturizers to glow-enhancing ampoules, all made with natural, premium ingredients. Since adding their products to my routine, my skin has never looked and felt better. A must-try for anyone seeking high-quality, affordable skincare.",
  },
  {
    id: 2,
    date: "",
    src: ba_farah,
    type: "Farah Goh",
    detail:
      "Empowering wellness has always been my passion, and Lavisha's vision of Holistic Wellness through Convergence aligns perfectly with my goals. Joining Lavisha means being part of its Eco-System that seamlessly blends Beauty, Wellness, and Innovation—truly Empowering Your Glow. Excited to be on this journey!",
  },
  {
    id: 3,
    date: "",
    src: ba_zizi,
    type: "Zizi Hau",
    detail:
      "Lavisha Malaysia has made me aware of my passion for holistic wellness and has helped me find the right balance in all aspects of my well-being. ",
  },
];

export default baNews;
